@import 'vars';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font, sans-serif;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color: #{$color-white};
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

// span {
//   font-size: 14px;
// }

li {
  list-style-type: none;
}

