$color-main: #ff607f;
$color-prime: #4bc0af;
$color-main-dark: #292929;
$color-second: #00A6B7;

$color-white: #f6f5e4;
$color-grey: #d3d3d3;
$color-light: #d4ece8;
$color-dark: #2c4143;

$color-text-grey: #787878;
$color-modal-bg: #F4F4F4;

$font: 'Montserrat', sans-serif;
