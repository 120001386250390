@import 'styles/_vars.scss';

.start {
  max-width: 800px;
  width: 100%;
  margin: 40px auto;
  text-align: center;
  padding: 0 24px;
  min-height: calc(100vh - 130px);
  color: var(--color);
  font-size: 20px;
  letter-spacing: .4px;
  font-weight: 600;
  h1 {
    margin-bottom: 25px;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 40px;
    margin-bottom: 24px;
  }
  & > div:nth-child(odd) {
    color: $color-main;
  }
}

.lining {
  text-align: justify;
  max-width: 400px;
}
.doesntmatter {
  font-size: 60%;
  color: rgba($color-grey, .4);
  margin: 48px auto;
}

.icon {
  margin-top: 14px;
  width: 240px;
  height: 240px;
  background: url('../assets/paraparapam.png') no-repeat center/contain;
  border-radius: 50%;
}

.projects {
  padding-top: 48px;
  & > p {
    max-width: 640px;
    margin-bottom: 48px;
    color: var(--color);
    text-align: center;
    @media (max-width: 440px) {
      width: 80%;
    }
  }
}

.apost {
  max-width: 240px;
  color: darken($color-grey, 45%);
  margin-top: 24px;
  font-size: 12px;
  text-align: center;
  line-height: 1.4;
}
